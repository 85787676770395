<template>
  <div class="stack vertical">
    <div
      v-for="(part, i) in form.parts"
      class="stack vertical gap-3 bg-gradient-to-b from-white/20 via-transparent to-gray-300/10 border-b last:border-b-0 border-gray-200 px-5 @2xl:px-7 py-5"
    >
      <h3 v-if="form.parts.length > 1" class="text-lg leading-tight">
        {{ part.partName ?? t('generalFeedback') }}
      </h3>
      <!-- @vue-ignore -->
      <s-input-field
        v-if="part.pointsAvailable"
        id="pointsEarned"
        type="number"
        step="0.01"
        :label="t('points')"
        v-model="part.pointsEarned"
        :min="0"
        :max="part.pointsAvailable"
        :error="errors?.parts?.[i]?.pointsEarned"
        inputClass="!rounded-r-none"
      >
        <template #suffix>
          <span class="text-gray-400 font-medium">/</span>
          <span>
            {{ t('xPoints', {count: part.pointsAvailable}, part.pointsAvailable) }}
          </span>
        </template>
      </s-input-field>
      <template v-for="(_, j) of part.feedback">
        <s-textarea-field
          id="feedback"
          v-model="part.feedback[j]"
          :error="errors?.parts?.[i]?.feedback?.[j]"
          :label="form.parts.length > 1 ? t('feedback') : t('generalFeedback')"
        />
        <s-btn
          v-if="part.feedback.length > 1"
          @click.prevent="removePartFeedback(part.partName, j)"
          color="primary-light"
        >
          <s-icon name="delete" />
          {{ t('deleteFeedback') }}
        </s-btn>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import ManualGradingSubmissionDto = App.DTOs.AssignmentMarking.ManualGradingSubmissionDto;
import SInputField from '../../../design-system/SInputField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import {useI18n} from 'vue-i18n';
import {useValidationErrors} from '../../../util/validation-errors';
import {InertiaForm} from '@inertiajs/vue3';
import Rubric = App.DTOs.Rubrics.Rubric;
import SBtn from '../../../design-system/SBtn.vue';
import SIcon from '../../../design-system/SIcon.vue';
import {TaskFeedback} from '../../../types/entities/tasks';
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;

const {t} = useI18n();

const props = defineProps<{
  form: InertiaForm<ManualGradingSubmissionDto>;
  feedbackByPart: Record<string, TaskFeedback[]>;
  partGrades: TaskCriterionGradeDto[];
  taskRubric?: Rubric | null;
  taskWeight?: number | null;
}>();

function removePartFeedback(partName: string | null, partIndex: number) {
  const index = props.form.parts.findIndex((p) => p.partName === partName);
  if (index >= 0 && partIndex >= 0) {
    const partFeedback = props.form.parts[index].feedback;

    // If found, delete the feedback at the specified index
    if (partFeedback && partIndex < partFeedback.length) {
      partFeedback.splice(partIndex, 1);
    }

    // Make sure we don't delete all feedback and prevent people from editing
    if (partFeedback.length <= 0) {
      partFeedback.push('');
    }
  }
}

const {errors} = useValidationErrors(props.form, 'errors');
</script>

<i18n>
{
  "en": {
    "deleteFeedback": "Delete Feedback"
  },
  "fr": {
    "deleteFeedback": "Retirer les Commentaires"
  }
}
</i18n>
