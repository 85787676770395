<script setup lang="ts">
import SLatex from '../../../components/SLatex.vue';
import {TaskFeedback} from '../../../types/entities/tasks';
import {computed} from 'vue';
import {comparePartNames} from '../../../util/feedback';
import {formatFraction} from '../../../format/grades';
import SBadge from '../../../design-system/SBadge.vue';
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

const props = defineProps<{
  feedbackByPart: Record<string, TaskFeedback[]> | null;
  partGrades: TaskCriterionGradeDto[];
  useHtmlNewlines?: boolean;
  class?: string;
}>();

const sortedFeedbacks = computed(() => {
  const feedback = props.feedbackByPart ?? {};
  const uniqueParts = new Set<string>([
    ...Object.keys(feedback),
    ...props.partGrades.map((g) => g.part ?? 'default'),
  ]);

  const parts = Array.from(uniqueParts);
  parts.sort(comparePartNames);

  return parts.map((part) => ({
    part,
    partGrade: props.partGrades.find((g) => (g.part ?? 'default') === part) ?? null,
    items: feedback[part] ?? [],
  }));
});

const onlyHasGeneralFeedback = computed(
  () => sortedFeedbacks.value.length === 1 && sortedFeedbacks.value[0].part === 'default'
);

const badgeColor = ({
  pointsEarned,
  pointsAvailable,
}: Pick<TaskCriterionGradeDto, 'pointsEarned' | 'pointsAvailable'>) => {
  if (pointsEarned === pointsAvailable) {
    return 'green';
  }

  if (pointsEarned > 0) {
    return 'blue';
  }

  return 'red';
};

function replaceNewlines(feedback: string) {
  if (props.useHtmlNewlines) {
    return feedback.replace(/\r\n|\r|\n/g, '<br>');
  }
  return feedback;
}
</script>

<template>
  <div v-for="{items, partGrade, part} in sortedFeedbacks" class="flex gap-4" :class="props.class">
    <div class="flex-1 flex flex-col">
      <h3 v-if="!onlyHasGeneralFeedback && part !== 'default'" class="font-bold">
        <s-latex :content="part" />
      </h3>

      <p v-if="items.length === 1">
        <s-latex :content="replaceNewlines(items[0].content)" :use-html="useHtmlNewlines" />
      </p>
      <ol v-else>
        <li v-for="feedback in items">
          <s-latex :content="replaceNewlines(feedback.content)" :use-html="useHtmlNewlines" />
        </li>
      </ol>
    </div>
    <div v-if="partGrade?.pointsAvailable && !onlyHasGeneralFeedback" class="flex-shrink-0">
      <s-badge :color="badgeColor(partGrade)" size="sm">
        {{ formatFraction(partGrade.pointsEarned, partGrade.pointsAvailable) }}
      </s-badge>
    </div>
  </div>
</template>
