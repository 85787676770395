import {computed, Ref} from 'vue';
import {comparePartNames} from '../util/feedback';
import {useForm} from '@inertiajs/vue3';
import {TaskFeedback} from '../types/entities/tasks';
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;
import TaskGradeDto = App.DTOs.TaskGradeDto;
import ManualGradingSubmissionDto = App.DTOs.AssignmentMarking.ManualGradingSubmissionDto;
import ManualGradingPartDto = App.DTOs.AssignmentMarking.ManualGradingPartDto;
import Rubric = App.DTOs.Rubrics.Rubric;

export interface UseManualGradeOverrideFormOptions {
  taskRubric: Ref<Rubric | null>;

  responseGrade: Ref<TaskGradeDto | null>;
  feedbackByPart: Ref<Record<string, TaskFeedback[]>>;
  partGrades: Ref<TaskCriterionGradeDto[]>;
  taskWeight: Ref<number>;
}

export function useManualGradeOverrideForm({
  taskRubric,
  responseGrade,
  feedbackByPart,
  partGrades,
  taskWeight,
}: UseManualGradeOverrideFormOptions) {
  const remainderPointsEarned = computed(() => {
    const grade = responseGrade.value;
    if (!grade) {
      return 0;
    }

    let pointsEarned: number;
    if (grade.isManuallyEntered && !partGrades.value.length) {
      pointsEarned = (grade.originalNumerator / grade.originalDenominator) * taskWeight.value;
    } else {
      const accountedPoints = partGrades.value.reduce(
        (acc, grade) => (!grade.part ? acc : acc + grade.pointsEarned),
        0
      );

      pointsEarned = grade.pointsReceived - accountedPoints;
    }

    return Math.max(0, parseFloat(pointsEarned.toFixed(2)));
  });

  const remainderPointsAvailable = computed(() => {
    const grade = responseGrade.value;
    if (!grade || (grade.isManuallyEntered && !partGrades.value.length)) {
      return taskWeight.value;
    }

    const accountedPoints = partGrades.value.reduce(
      (acc, grade) => (!grade.part ? acc : acc + grade.pointsAvailable),
      0
    );

    return Math.max(0, grade.pointsAvailable - accountedPoints);
  });

  function buildSortedPartNames(
    rubricPartNames: string[],
    existingFeedbackPartNames: string[],
    partGradesByPartName: Record<string, TaskCriterionGradeDto>
  ) {
    // Start with general feedback
    const allSortedPartNames: string[] = ['default'];
    const allPartNames = new Set<string>(allSortedPartNames);

    // Add the rubric parts in order
    for (const partName of rubricPartNames) {
      if (allPartNames.has(partName)) {
        continue;
      }

      allPartNames.add(partName);
      allSortedPartNames.push(partName);
    }

    // Remainder is anything that was originally in the data, either from feedback or part grades.
    // This will mostly apply to legacy/code-driven tasks without a rubric.
    const remainingPartNames = [...existingFeedbackPartNames, ...Object.keys(partGradesByPartName)];
    remainingPartNames.sort(comparePartNames);

    for (const partName of remainingPartNames) {
      if (allPartNames.has(partName)) {
        continue;
      }

      allPartNames.add(partName);
      allSortedPartNames.push(partName);
    }

    return allSortedPartNames;
  }

  return useForm<ManualGradingSubmissionDto>(() => {
    const existingFeedbackPartNames = Object.keys(feedbackByPart.value ?? {});

    const partGradesByPartName = Object.fromEntries(
      partGrades.value?.map((g) => [g.part ?? 'default', g]) ?? []
    );

    // We aggregate all available points for rubric parts, since there can be multiple
    // rubric parts corresponding to the same part name
    const rubricPartNames: string[] = [];
    const rubricPartNameToPointsAvailable: Record<string, number> = {};
    for (const partRubric of taskRubric.value?.parts ?? []) {
      const resolvedPartName = partRubric.partName ?? 'default';

      rubricPartNames.push(resolvedPartName);
      rubricPartNameToPointsAvailable[resolvedPartName] =
        (rubricPartNameToPointsAvailable[resolvedPartName] || 0) +
        (partRubric.pointsAvailable ?? 0);
    }

    const allSortedPartNames = buildSortedPartNames(
      rubricPartNames,
      existingFeedbackPartNames,
      partGradesByPartName
    );

    const existingParts: ManualGradingPartDto[] = [];
    for (const partName of allSortedPartNames) {
      const partGrade: TaskCriterionGradeDto | undefined = partGradesByPartName[partName];

      let pointsAvailable: number;
      let pointsEarned: number;

      if (taskRubric.value) {
        pointsAvailable = rubricPartNameToPointsAvailable[partName] || 0;
        pointsEarned = Math.max(0, Math.min(pointsAvailable, partGrade?.pointsEarned || 0));
      } else {
        pointsAvailable =
          partName === 'default' ? remainderPointsAvailable.value : partGrade?.pointsAvailable || 0;
        pointsEarned =
          partName === 'default' ? remainderPointsEarned.value : partGrade?.pointsEarned || 0;
      }

      existingParts.push({
        partName: partName === 'default' ? null : partName,
        pointsEarned,
        pointsAvailable,
        feedback: feedbackByPart.value[partName]?.map((feedback) => feedback.content) ?? [''],
      });
    }

    return {
      parts: existingParts,
    };
  });
}
